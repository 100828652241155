var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('transition',{attrs:{"name":"fade"}},[(_vm.loaded)?_c('div',[_c('div',{staticClass:"articleHead"},[_c('div',{staticClass:"container"},[_c('bread-crumbs',{staticClass:"bc1",attrs:{"last":true,"items":[
							{
								text: _vm.$t('pages.main'),
								url: {
									name: 'MainPage',
									params: {
										locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale,
									},
								},
							},
							{
								text: _vm.$t('pages.news'),
								url: {
									name: 'news',
									params: {
										locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale,
									},
								},
							} ].concat( _vm.categoryBc )}}),_c('div',{staticClass:"backWrp"},[_c('router-link',{attrs:{"to":{
								name: 'news',
								params: {
									locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale,
								},
							}}},[_vm._v(_vm._s(_vm.$t("back")))])],1),_c('div',{staticClass:"articleCaption"},[(_vm.model)?_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.model.public_date))]):_vm._e(),(_vm.translate)?_c('h1',[_vm._v(_vm._s(_vm.translate.name))]):_vm._e(),_c('share-bar')],1)],1)]),_c('div',{staticClass:"articleBodyWrp"},[_c('div',{staticClass:"container"},[(_vm.model)?_c('img',{staticClass:"mainIllustration",attrs:{"src":_vm.path(_vm.model.image),"alt":_vm.path(_vm.model.image)}}):_vm._e(),_c('div',{staticClass:"articleBody"},[_c('div',{staticClass:"captionsWrp"},[(_vm.anchors.length)?_c('side-article-captions',{attrs:{"items":_vm.anchors}}):_vm._e()],1),_c('div',[(_vm.model.file)?_c('a',{staticClass:"docLink",attrs:{"href":_vm.pathFile(_vm.model.file),"target":"_blank"}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("downloadPdf")))]),_c('span',{staticClass:"ic-donwload"})]):_vm._e(),_c('div',{ref:"articletext",staticClass:"ArticleText"},_vm._l((_vm.constructor),function(item,index){return _c(item.component,{key:index,tag:"component",attrs:{"d":item.content}})}),1),_c('router-link',{staticClass:"button2 left",attrs:{"to":{ name: 'news', params: { locale: _vm.$i18n.locale } }}},[_vm._v(_vm._s(_vm.$t('back'))+" "),_c('span',{staticClass:"ic-icarrow-left"})]),_c('share-bar',{attrs:{"propstyle":{
									marginTop: '25px',
									borderTop: "1px solid #D3D6E7",
								}}})],1)])])]),_c('news-section',{attrs:{"theme":"light","linkverse":"back","d":_vm.similar_posts}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }