<template>
	<div class="shareWrp" :style="propstyle">
		<div class="subscriveTxt">{{$t('share')}}</div>
		<div class="soc">
			<a
				:href="`https://www.facebook.com/sharer/sharer.php?u=${currentLocation}`"
				target="_blank"
			>
				<span class="ic-icfb"></span>
			</a>
			<a
				:href="`https://twitter.com/intent/tweet?text=${currentLocation}`"
				target="_blank"
			>
				<span class="ic-twitter"></span>
			</a>
			<a
				:href="`https://telegram.me/share/url?url=${currentLocation}`"
				target="_blank"
			>
				<span class="ic-ictl"></span>
			</a>
			<a href="#" @click.prevent="copyToClipboard">
				<span class="ic-share"></span>
				<span v-if="isCopiedLabel" class="copyText">{{
					$t("textCopyed")
				}}</span>
			</a>
			<input type="text" class="copy" ref="copyInpt" />
		</div>
	</div>
</template>

<script>
// TODO реализовать share-bar
export default {
	name: "ShareBar",
	props: ["propstyle"],
	data() {
		return {
			copiedTimer: null,
			isCopiedLabel: false,
		};
	},
	methods: {
		copyToClipboard() {
			this.$refs.copyInpt.value = window.location.href;
			this.$refs.copyInpt.select();
			document.execCommand("copy");
			this.isCopiedLabel = true;
			this.copiedTimer = setTimeout(() => {
				this.isCopiedLabel = false;
			}, 2000);
		},
	},
	computed: {
		currentLocation() {
			return window.location.href;
		},
	},
};
</script>

<style lang="scss">
.shareWrp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	.subscriveTxt {
		@include t4;
		color: $cgray3;
	}
	.soc {
		display: flex;
		align-items: center;
		a {
			color: $cgray3;
			text-decoration: none;
			font-size: 20px;
			margin: 0 5px;
			&:hover {
				color: #393b4b;
			}
		}
	}
	position: relative;
	.copy {
		position: fixed;
		// pointer-events: none;
		left: 100%;
	}
	.copyText {
		background: #1d1d1d;
		padding: 16px;
		border-radius: 3px;
		position: absolute;
		top: calc(100% + 20px);
		right: 0;
		white-space: nowrap;
	}
}
</style>