<template>
	<div class="quote">
		<div class="txt" v-html="d.text"></div>
		<div class="auth">{{ d.title }}</div>
	</div>
</template>

<script>
export default {
	name: "Quotes",
};
</script>

<style lang="scss" >
.txt {
	display: inline-block;
	p {
		display: inline-block;
		&::before {
			content: "«";
		}
		&::after {
			content: "»";
		}
	}
}
</style>