<template>
	<main>
		<transition name="fade">
			<div v-if="loaded">
				<div class="articleHead">
					<div class="container">
						<bread-crumbs
							:last="true"
							:items="[
								{
									text: $t('pages.main'),
									url: {
										name: 'MainPage',
										params: {
											locale: $i18n.locale == 'uk' ? null : $i18n.locale,
										},
									},
								},
								{
									text: $t('pages.news'),
									url: {
										name: 'news',
										params: {
											locale: $i18n.locale == 'uk' ? null : $i18n.locale,
										},
									},
								},
								...categoryBc,
							]"
							class="bc1"
						></bread-crumbs>
						<div class="backWrp">
							<router-link
								:to="{
									name: 'news',
									params: {
										locale: $i18n.locale == 'uk' ? null : $i18n.locale,
									},
								}"
								>{{ $t("back") }}</router-link
							>
						</div>
						<div class="articleCaption">
							<div class="date" v-if="model">{{ model.public_date }}</div>
							<h1 v-if="translate">{{ translate.name }}</h1>
							<share-bar></share-bar>
						</div>
					</div>
				</div>
				<div class="articleBodyWrp">
					<div class="container">
						<img
							v-if="model"
							:src="path(model.image)"
							:alt="path(model.image)"
							class="mainIllustration"
						/>
						<div class="articleBody">
							<div class="captionsWrp">
								<side-article-captions
									v-if="anchors.length"
									:items="anchors"
								></side-article-captions>
							</div>
							<div>
								<a
									v-if="model.file"
									:href="pathFile(model.file)"
									target="_blank"
									class="docLink"
								>
									<span class="label">{{ $t("downloadPdf") }}</span>
									<span class="ic-donwload"></span>
								</a>
								<div class="ArticleText" ref="articletext">
									<component
										v-for="(item, index) in constructor"
										:key="index"
										:is="item.component"
										:d="item.content"
									></component>
								</div>
								<router-link
									:to="{ name: 'news', params: { locale: $i18n.locale } }"
									class="button2 left"
									>{{ $t('back') }} <span class="ic-icarrow-left"></span
								></router-link>
								<share-bar
									:propstyle="{
										marginTop: '25px',
										borderTop: `1px solid #D3D6E7`,
									}"
								></share-bar>
							</div>
						</div>
					</div>
				</div>
				<news-section
					theme="light"
					linkverse="back"
					:d="similar_posts"
				></news-section>
			</div>
		</transition>
	</main>
</template>

<script>
import Gallery from "../components/ConstructorTextParts/Gallery.vue";
import ImageElement from "../components/ConstructorTextParts/ImageElement.vue";
import List from "../components/ConstructorTextParts/List.vue";
import Quotes from "../components/ConstructorTextParts/Quotes.vue";
import SimpleText from "../components/ConstructorTextParts/SimpleText.vue";
import BreadCrumbs from "../components/Parts/BreadCrumbs.vue";
import ShareBar from "../components/Parts/ShareBar.vue";
import SideArticleCaptions from "../components/Parts/SideArticleCaptions.vue";
import NewsSection from "../components/Sections/NewsSection.vue";
export default {
	components: {
		BreadCrumbs,
		SideArticleCaptions,
		ShareBar,
		NewsSection,
		SimpleText,
		Quotes,
		List,
		Gallery,
		ImageElement,
	},
	name: "ArticleViewShowcase",
	data() {
		return {
			loaded: false,
			model: null,
			translate: null,
			constructor: null,
			similar_posts: null,

			anchors: [],
		};
	},
	computed: {
		categoryBc() {
			return {
				text: this.model.category_name,
				url: {
					name: "newscategory",
					params: {
						locale: this.locale == "uk" ? null : this.locale,
						category: this.model.category_slug,
					},
				},
			};
		},
	},
	created() {
		this.axios
			.post("/api/news/get-by-slug", {
				lang: this.$i18n.locale,
				slug: this.$route.params.slug,
			})
			.then((response) => {
				// if (this.$store.getters.newsTitle)
				// 	document.title = this.$store.getters.newsTitle;
				// this.newsList = response.data.data.models;
				this.model = response.data.data.model;
				this.translate = response.data.data.translate;
				this.constructor = response.data.data.constructor;
				this.similar_posts = response.data.data.similar_posts;
				document.title =
					response.data.data.translate.meta_title ||
					response.data.data.translate.name;
				// this.currentPage = response.data.data.paginate.current_page;
				// this.totalPage = Math.ceil(
				// 	response.data.data.paginate.total /
				// 		response.data.data.paginate.per_page
				// );
				this.loaded = true;
			})
			.catch(({ response }) => {
				this.solveStatus(response.status);
			});
	},
	mounted() {
		setTimeout(() => {
			let indexes = this.$refs.articletext.querySelectorAll("h2,h3,h4,h5,h6");
			let i = 0;
			this.anchors = [];
			indexes.forEach((element) => {
				let id = `articleAncors${i++}`;
				element.id = id;
				this.anchors.push({
					id: id,
					text: element.innerHTML,
				});
			});
		}, 500);
	},
};
</script>

<style lang="scss">
.articleHead {
	padding: 30px 0 10px;
	.container {
		@include container;
		display: grid;
		grid-template-columns: 25% 58.33% 16.66%;
		@media (max-width: $tm) {
			grid-template-columns: 1fr;
		}
		.bc1 {
			@media (max-width: $tm) {
				display: none;
			}
		}
		.backWrp {
			display: none;
			@media (max-width: $tm) {
				display: block;
			}
			a {
				@include t4;
				color: $cred1;
			}
		}
		.articleCaption {
			@media (max-width: $tm) {
				margin-top: 10px;
			}
			.date {
				@include t4;
				color: $cgray3;
			}
			h1 {
				@include h2mob;
				margin: 10px 0 30px;
			}
		}
	}
}
.articleBodyWrp {
	.docLink {
		margin-top: 10px;
		display: flex;
		align-items: center;
		text-decoration: none;
		margin-bottom: 30px;
		.label {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 130%;
			text-decoration-line: underline;
			color: #e1251b;
		}
		.ic-donwload {
			margin-left: 15px;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background-color: $cred1;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			color: #fff;
		}
	}
	.container {
		@include container;
		.mainIllustration {
			width: 100%;
		}
		.articleBody {
			padding: 30px 0 0;
			display: grid;
			grid-template-columns: 25% 58.33% 16.66%;
			@media (max-width: $tm) {
				grid-template-columns: 1fr;
			}
			.captionsWrp {
				padding-bottom: 30px;
			}
			.ArticleText {
				& > div:first-of-type {
					*:first-of-type {
						margin-top: 0;
					}
				}
				& > *:first-of-type {
					margin-top: 0;
				}
				p {
					@include t3l;
				}
				h2 {
					@include h2mob;
					margin: 35px 0 25px;
				}
				h3 {
					@include h3;
					margin: 35px 0 25px;
				}
				h4 {
					@include h4;
					margin: 30px 0 20px;
				}
				h5 {
					@include h5;
					margin: 25px 0 20px;
				}
				h6 {
					@include h6;
					margin: 25px 0 15px;
				}
				ol {
					list-style: none;
					counter-reset: olcounter;
					padding: 0;
					padding-left: 25px;

					li {
						@include t3l;
						margin: 16px 0;
						position: relative;
						counter-increment: olcounter;

						&::before {
							content: counter(olcounter) ".";
							position: absolute;
							top: 0px;
							left: -22px;
							@include t4;
							color: $cred1;
						}

						& > p {
							margin: 6px 0;

							& + p {
								margin-top: 16px;
							}
						}
					}
				}
				ul {
					list-style: square;

					padding: 0;
					padding-left: 25px;

					li {
						@include t3l;
						margin: 16px 0;
						position: relative;

						&::marker {
							color: $cred1;
						}
					}

					ol > li:before {
						content: counter(olcounter) ".";
						position: absolute;
						top: -1px;
						left: -30px;
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						color: #000000;
						background-color: transparent;
					}
				}
				& > img {
					width: 100%;
					margin: 16px 0;
				}
				.quote {
					.txt {
						font-style: italic;
						font-weight: 500;
						font-size: 18px;
						line-height: 130%;
						letter-spacing: 0.01em;
						color: #111325;
						* {
							font-style: italic;
							font-weight: 500;
							font-size: 18px;
							line-height: 130%;
							letter-spacing: 0.01em;
							color: #111325;
						}
					}
					.auth {
						@include h6;
						margin-top: 15px;
						padding-bottom: 15px;
						&::before {
							content: "";
							height: 3px;
							width: 16px;
							margin-bottom: 4px;
							margin-right: 10px;
							background-color: $cred1;
							display: inline-block;
						}
					}
				}
			}
			.button2 {
				margin-top: 20px;
				width: 100%;
				max-width: unset;
			}
		}
	}
}
</style>
