<template>
	<div class="newsItem">
		<div>
			<div class="date">{{ d.public_date || d.date }}</div>
			<router-link v-if="d.url" :to="d.url" target="_blank"
				><img
					v-if="d.image"
					class="newPreview"
					:src="path(d.image)"
					:alt="path(d.image)"
			/></router-link>
		</div>
		<div>
			<router-link
				v-if="d.category"
				:to="d.category.url"
				target="_blank"
				class="rub"
			>
				<template v-if="d.category">{{ d.category.name }}</template>
			</router-link>
			<router-link :to="d.url" target="_blank" class="caption">{{
				d.name
			}}</router-link>
			<div class="text" v-html="d.text"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "NewsListItem",
};
</script>

<style lang='scss'>
.newsItem {
	text-decoration: none;
	display: grid;
	grid-template-columns: 244px 1fr;
	grid-gap: 20px;
	border-top: 1px solid $cgray4;
	padding-top: 10px;
	padding-bottom: 25px;
	@media (max-width: $tm) {
		grid-template: auto / 150px 1fr;
	}
	@media (max-width: $ts) {
		display: block;
	}
	.date {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 135%;
		letter-spacing: 0.01em;
		color: #7f8192;
		padding: 5px 0;
	}
	.newPreview {
		width: 100%;
		height: 137px;
		object-fit: cover;
	}
	.rub {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 130%;
		text-decoration-line: underline;
		color: #7f8192;
	}
	.caption {
		display: block;
		text-decoration: none;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: #111325;
		margin: 10px 0 0;
	}
	.text {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 135%;
		letter-spacing: 0.01em;
		color: #111325;
		margin: 10px 0 0;
	}
}
</style>