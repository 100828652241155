<template>
	<section class="imageCaorusel" :class="theme">
		<div class="container1">
			<h2>{{d.title}}</h2>
			<Flickity
				class="imgsSlider"
				ref="imgsSlider"
				:options="ImgsSliderOptions"
				v-images-loaded.on.progress="resize"
			>
				<img
					v-for="(item, index) in slides"
					:key="index"
					:src="path(item.image)"
					:alt="item"
					class="slide"
				/>
			</Flickity>
			<div class="controls">
				<div class="btns">
					<div class="prew" @click="$refs.imgsSlider.previous()">
						<span class="ic-icarrow-left"></span>
					</div>
					<div class="next" @click="$refs.imgsSlider.next()">
						<span class="ic-icarrow-right"></span>
					</div>
				</div>
			</div>
		</div>
		<modal-gallery
			v-if="modalGal"
			:compdata="slides"
			@close="modalGal = !modalGal"
			:curIndex="currentImg"
		></modal-gallery>
	</section>
</template>

<script>
import imagesLoaded from "vue-images-loaded";
import Flickity from "vue-flickity";
import ModalGallery from "../Parts/ModalGallery.vue";
export default {
	name: "Gallery",
	directives: {
		imagesLoaded,
	},
	components: {
		Flickity,
		ModalGallery,
	},
	props: ["theme"],
	data() {
		return {
			ImgsSliderOptions: {
				prevNextButtons: false,
				// cellAlign: "left",
				contain: true,
				pageDots: false,
				// groupCells: true,
				// asNavFor: ".historyInfoSlider",
			},

			currentImg: 1,
			modalGal: false,
		};
	},
	methods: {
		resize() {
			this.$refs.imgsSlider.resize();
		},
	},
	computed: {
		slides() {
			let a = [];
			for (const key in this.d.list) {
				if (Object.hasOwnProperty.call(this.d.list, key)) {
					const element = this.d.list[key];
					a.push(element);
				}
			}
			return a;
		}
	},
	mounted() {
		this.$refs.imgsSlider.on(
			"staticClick",
			(event, pointer, cellElement, cellIndex) => {
				this.currentImg = cellIndex + 1;
				this.modalGal = true;
			}
		);
	},
};
</script>

<style lang='scss'>
.imageCaorusel {
	padding: 30px 0;
	overflow: hidden;
	.container1 {
		// @include container;
		h2 {
			@include h3;
		}
		.imgsSlider {
			margin-top: 26px;
			width: 100%;
			.flickity-viewport {
				overflow: unset;
			}
			img.slide {
				height: 440px;
				margin-right: 10px;
				@media (max-width: $ts) {
					height: 300px;
				}
			}
		}
		.controls {
			display: flex;
			justify-content: flex-end;
			margin-top: 11px;
			.btns {
				width: 50%;
				display: flex;
				border-top: 1px solid $cgray3;
				border-bottom: 1px solid $cgray3;
				@media (max-width: $tl) {
					width: 100%;
				}
				.prew {
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					padding-left: 20px;
					height: 60px;
					border-right: 1px solid $cgray3;
					cursor: pointer;
					.ic-icarrow-left {
						font-size: 24px;
					}
				}
				.next {
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding-right: 20px;
					height: 60px;
					.ic-icarrow-right {
						font-size: 24px;
					}
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>