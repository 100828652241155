<template>
	<div>
		<h2 v-if="d.title">{{ d.title }}</h2>
		<div v-if="d.description" v-html="d.description"></div>
	</div>
</template>

<script>
export default {
	name: "SimpleText",
};
</script>

<style lang="scss" scoped>
</style>