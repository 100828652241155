<template>
	<div>
		<h2 v-if="d.title">{{ d.title }}</h2>
		<img :src="path(d.image)" :alt="path(d.image)" />
	</div>
</template>

<script>
export default {
	name: "ImageElement",
};
</script>

<style lang="scss" scoped>
img{
	width: 100%;
	margin: 16px 0;
}
</style>