<template>
	<div>
		<h2>{{d.title}}</h2>
		<ul v-if="d.type == 'ul'">
			<li v-for="(item, index) in d.list" :key="index" v-html="item.item"></li>
		</ul>
		<ol v-else>
			<li v-for="(item, index) in d.list" :key="index" v-html="item.item"></li>
		</ol>
	</div>
</template>

<script>
export default {
	name: "List",
};
</script>

<style lang="scss" scoped>
</style>