<template>
	<div class="sideCaptions desc" v-if="mode == 'desc'">
		<a
			href="#"
			v-for="(item, index) in items"
			:key="index"
			:class="{ active: index == currentId }"
			@click.prevent="onClick(item.id, index)"
			>{{ item.text }}</a
		>
	</div>
	<div v-else class="sideCaptions mob">
		<div class="mbtitle" @click="slide = !slide">
			Зміст<span class="ic-icexpand-down"></span>
		</div>
		<slide-up-down :active="slide">
			<a
				href="#"
				v-for="(item, index) in items"
				:key="index"
				:class="{ active: index == currentId }"
				@click.prevent="onClick(item.id, index)"
				>{{ item.text }}</a
			>
		</slide-up-down>
	</div>
</template>

<script>
export default {
	name: "SideArticleCaptions",
	props: ["items"],
	props: {
		items: {
			type: Array,
			default: null,
		},
		width: {
			type: Number,
			default: 954,
		},
	},
	data() {
		return {
			slide: false,
			mode: null,
			currentId: null,
		};
	},
	methods: {
		setType() {
			if (window.innerWidth > this.width) {
				this.mode = "desc";
			} else {
				this.mode = "mob";
			}
		},
		onClick(id, index) {
			this.currentId = index;
			let ele = document.getElementById(id);
			if (ele) {
				const rect = ele.getBoundingClientRect();
				const top = rect.top + window.scrollY - 30;
				window.scrollTo({
					top: top,
					behavior: "smooth",
				});
			}
		},
	},
	created() {
		this.setType();
		window.addEventListener("resize", this.setType);
	},
	destroyed() {
		window.removeEventListener("resize", this.setType);
	},
};
</script>

<style lang="scss">
.sideCaptions.desc {
	position: sticky;
	top: 30px;
	padding-right: 60px;
	a {
		@include t4;
		color: $cgray3;
		display: block;
		padding: 8px 0 8px 19px;
		position: relative;
		text-decoration: none;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 4px;
			background-color: $cgray5;
		}
		&:hover {
			color: $cblacktxt;
		}
		&.active {
			color: $cblacktxt;
			&::before {
				background-color: $cred1;
			}
		}
	}
}
.sideCaptions.mob {
	border: 2px solid #d3d6e7;
	border-left: 6px solid #d3d6e7;
	box-sizing: border-box;
	.mbtitle {
		@include t4;
		padding: 16px;
		background-color: $cgray6;
		position: relative;
		.ic-icexpand-down {
			cursor: pointer;
			font-size: 20px;
			top: 50%;
			right: 15px;
			position: absolute;
			transform: translate(0, -50%);
			color: $cgray3;
		}
	}
	a {
		display: block;
		@include t4;
		text-decoration: none;
		color: $cgray3;
		padding: 8px 8px 8px 15px;
		padding-right: 40px;
		position: relative;
		&.active {
			background-color: $cgray7;
		}
	}
}
</style>